<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import movesTable from './components/movesTable';

export default {
  page: {
    title: "Entrées Stock",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, movesTable },
  data() {
    return {
      tableData: {},
      stockMoveData: {
        type: "",
        tableTitle: "",
      },
      title: "",
      items: [
        {
          text: "Catalogue des services",
          to: {name:"service_catalog"},
        },
        {
          text: "Stock",
          to: {name:"service_catalog.stock"},
        },
        {
          text: "",
          active: true
        }
      ],
      
      fields: [
        { key: "name", sortable: true },
        { key: "address", sortable: true },
        { key: "analytic_account", sortable: true },
      ]
    };
  },

  methods: {
    updateStockMoveData(){
      var op = this.$route.params.op;
      switch (op) {
        case 'in':
            this.stockMoveData.type = 'in';
            this.stockMoveData.tableTitle = "Entrées de stock";
            this.title = "Entrées de stock";
            this.items[this.items.length - 1].text = this.title;
        break;

        case 'out':
            this.stockMoveData.type = 'out';
            this.stockMoveData.tableTitle = "Sorties de stock";
            this.title = "Sorties de stock";
        break;
      
        default:
          alert("shit");
        break;
      }
    }
  },

  mounted() {
    this.updateStockMoveData();
  },
  
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <moves-table :opType="stockMoveData.type" :tableTitle="stockMoveData.tableTitle"/>
  </Layout>
</template>